<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.22485 14.7615C1.22485 15.0599 1.48101 15.3009 1.79806 15.3009H14.9819C15.2989 15.3009 15.5551 15.0599 15.5551 14.7615V7.68251H1.22485V14.7615ZM14.9819 3.03057H11.9725V1.95186C11.9725 1.87769 11.9081 1.81702 11.8292 1.81702H10.8261C10.7473 1.81702 10.6828 1.87769 10.6828 1.95186V3.03057H6.09714V1.95186C6.09714 1.87769 6.03265 1.81702 5.95384 1.81702H4.95072C4.8719 1.81702 4.80742 1.87769 4.80742 1.95186V3.03057H1.79806C1.48101 3.03057 1.22485 3.27159 1.22485 3.56992V6.53638H15.5551V3.56992C15.5551 3.27159 15.2989 3.03057 14.9819 3.03057Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCalendar'
}
</script>
